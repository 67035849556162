import React from 'react';
import _ from 'lodash';
import { StatisticGroup, Segment, Divider } from 'semantic-ui-react';
import { StatisticProp } from '../../enum/apiResultProp/statisticGroup/StatisticProp';
import { StatisticGroupProp } from '../../enum/apiResultProp/statisticGroup/StatisticGroupProp';
import SingleStatistic from './SingleStatistic';
import ScreenTitle from '../title/ScreenTitle';

export default ({ screenComponentProps }) => {
    const inverted = screenComponentProps[StatisticGroupProp.Inverted];
    const segmentColor = screenComponentProps[StatisticGroupProp.SegmentColor];
    const statistics = screenComponentProps[StatisticGroupProp.Statistics];
    const title = screenComponentProps[StatisticGroupProp.Title];

    let segmentProps = segmentColor ? { color: segmentColor } : {};

    return (
        <Segment raised inverted={inverted} {...segmentProps}>
            {title ? <ScreenTitle uniqueKey={1} screenComponentProps={title} /> : null}
            {title ? <Divider /> : null}

            <StatisticGroup widths={_.keys(statistics).length} size='tiny'>
                {_.map(statistics, (statisticProps) => <SingleStatistic key={statisticProps[StatisticProp.Position]} props={statisticProps} parentIsInverted={inverted} />)}
            </StatisticGroup>
        </Segment>
    );
}
