import React from 'react';
import { Header, Icon, Grid } from 'semantic-ui-react';
import { TitleProp } from '../../enum/apiResultProp/TitleProp';

export default ({ screenComponentProps, uniqueKey }) => {
    const text = screenComponentProps[TitleProp.Text];
    const textSize = screenComponentProps[TitleProp.TextSize];
    const textAlign = screenComponentProps[TitleProp.TextAlign];
    const textColor = screenComponentProps[TitleProp.TextColor];
    const iconName = screenComponentProps[TitleProp.IconName];

    const headerProps = {
        as: textSize,
        textAlign: textAlign,
        color: textColor,
        key: uniqueKey,
        className: 'newScreenTitle'
    };
    if (iconName) {
        // headerProps.icon = true;
    }

    return (
        <Header {...headerProps}>
            <span>
                {iconName ? <Icon name={iconName} /> : null}
                <Header.Content>{text}</Header.Content>
            </span>
        </Header>
    );
}
