export const FormButtonProp = Object.freeze({
    ButtonType: 'buttonType',
    ButtonName: 'buttonName',
    Text: 'text',
    IconName: 'iconName',
    Disabled: 'disabled',
    Hidden: 'hidden',
    ShowSpinner: 'showSpinner',
    Color: 'color',
    ButtonSide: 'buttonSide',
    Position: 'position',
    NeededFieldNames: 'neededFieldNames',
    Action: 'action',
    PopupQuestion: 'popupQuestion',
    PopupPositiveText: 'popupPositiveText',
    PopupNegativeText: 'popupNegativeText',
    DisableOnActionRequest: 'disableOnActionRequest',
    EnableOnActionResponse: 'enableOnActionResponse',
    DisabledMessage: 'disabledMessage',
    Fluid: 'fluid',
    FreeIconStyle: 'freeIconStyle',
});