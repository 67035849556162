import React from 'react';
import { connect } from 'react-redux';
import {
    login, updateFormInput, generateValidationCodeForLoggedUserEmail, changeEmailSubmit,
    generateValidationCodeForEmailChangeNewEmail
} from '../actions/authActions';
import { Grid, Header, Icon, Form, Segment, Button, Message } from 'semantic-ui-react';

class ChangeEmail extends React.Component {
    onInputChange = (formName, fieldName, fieldValue) => {
        this.props.updateFormInput(formName, fieldName, fieldValue);
    }

    onCodeGenerateClickOldEmail = (formName, buttonName) => {
        this.props.generateValidationCodeForLoggedUserEmail(formName, buttonName);
    }

    onCodeGenerateClickNewEmail = (formName, buttonName, newEmail) => {
        this.props.generateValidationCodeForEmailChangeNewEmail(formName, buttonName, newEmail);
    }

    onSubmitClick = () => {
        this.props.changeEmailSubmit('changeEmailForm', 'submitForm', 'change-email', true);
    }

    renderErrorMessage = (message) => {
        if (!message) {
            return null;
        }

        return (
            <Message error size='mini' style={{ marginTop: '0px' }}>
                {message}
            </Message>
        );
    }

    renderSuccessMessage = (message) => {
        if (!message) {
            return null;
        }

        return (
            <Message success size='mini' style={{ marginTop: '0px' }}>
                {message}
            </Message>
        );
    }

    renderForm = () => {
        return (
            <Grid centered columns={1} textAlign='center' style={{ marginTop: '0.5em' }}>
                <Grid.Row>
                    <Grid.Column centered style={{ width: '40%' }}>
                        <Segment raised>
                            <Header as='h4' style={{ color: "#3C8AD9" }} textAlign='center'>
                                <Icon name="mail" /> Промяна на Имейл
                            </Header>
                            {this.renderErrorMessage(this.props.auth.changeEmailForm.message.errorMessage)}
                            {this.renderSuccessMessage(this.props.auth.changeEmailForm.message.successMessage)}
                            <Form size='tiny' warning>
                                <Form.Input
                                    action={{
                                        loading: this.props.auth.changeEmailForm.buttonIsInAction.generateValidationCodeOldEmail,
                                        disabled: this.props.auth.changeEmailForm.buttonIsInAction.generateValidationCodeOldEmail,
                                        size: 'tiny',
                                        content: 'Изпрати ми верификационен код',
                                        color: 'teal',
                                        onClick: () => this.onCodeGenerateClickOldEmail('changeEmailForm', 'generateValidationCodeOldEmail'),
                                        icon: 'keyboard',
                                        style: { backgroundColor: "#3C8AD9" }
                                    }}
                                    icon='mail'
                                    iconPosition='left'
                                    label='Стар Имейл'
                                    readOnly={true}
                                    value={this.props.auth.email}
                                />
                            </Form>
                            <Form size='tiny'>
                                <Form.Input
                                    icon='keyboard'
                                    iconPosition='left'
                                    label='Въведи кода получен на стария имейл'
                                    value={this.props.auth.changeEmailForm.fields.oldEmailVerificationCode}
                                    onChange={(e) => this.onInputChange('changeEmailForm', 'oldEmailVerificationCode', e.target.value)}
                                />
                            </Form>
                            <Form size='tiny'>
                                <Form.Input
                                    action={{
                                        loading: this.props.auth.changeEmailForm.buttonIsInAction.generateValidationCode,
                                        disabled: this.props.auth.changeEmailForm.buttonIsInAction.generateValidationCode,
                                        size: 'tiny',
                                        content: 'Изпрати ми верификационен код',
                                        color: 'teal',
                                        onClick: () => this.onCodeGenerateClickNewEmail('changeEmailForm', 'generateValidationCode', this.props.auth.changeEmailForm.fields.newEmail),
                                        icon: 'keyboard',
                                        style: { backgroundColor: "#3C8AD9" }
                                    }}
                                    icon='mail'
                                    iconPosition='left'
                                    label='Нов Имейл'
                                    value={this.props.auth.changeEmailForm.fields.newEmail}
                                    onChange={(e) => this.onInputChange('changeEmailForm', 'newEmail', e.target.value)}
                                />
                            </Form>
                            <Form size='tiny'>
                                <Form.Input
                                    icon='keyboard'
                                    iconPosition='left'
                                    label='Въведи кода получен на новия имейл'
                                    value={this.props.auth.changeEmailForm.fields.newEmailVerificationCode}
                                    onChange={(e) => this.onInputChange('changeEmailForm', 'newEmailVerificationCode', e.target.value)}
                                />
                                <Form.Input
                                    type='password'
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    label='Парола'
                                    value={this.props.auth.changeEmailForm.fields.password}
                                    onChange={(e) => this.onInputChange('changeEmailForm', 'password', e.target.value)}
                                />
                                <Button
                                    loading={this.props.auth.changeEmailForm.buttonIsInAction.submitForm}
                                    disabled={this.props.auth.changeEmailForm.buttonIsInAction.submitForm}
                                    fluid
                                    size='tiny'
                                    style={{ backgroundColor: "#3C8AD9", color: '#FFF' }}
                                    onClick={this.onSubmitClick}
                                >
                                    Промени Имейл
                                </Button>
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    render() {
        return (
            <>
                {this.renderForm()}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth };
}

const actions = {
    login, updateFormInput, generateValidationCodeForLoggedUserEmail, changeEmailSubmit,
    generateValidationCodeForEmailChangeNewEmail
}

export default connect(mapStateToProps, actions)(ChangeEmail);
