import React from 'react';
import _ from 'lodash';
import { ButtonGroup, Button, ButtonOr } from 'semantic-ui-react';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';
import { ButtonOptionsFormFieldOptionProp } from '../../../enum/apiResultProp/buttonOptionsForm/ButtonOptionsFormFieldOptionProp';

export default ({ fieldProps, onValueChange }) => {
    const name = fieldProps[FormFieldProp.Name];
    const options = fieldProps[FormFieldProp.Options];
    const selectedValue = fieldProps[FormFieldProp.Value];

    const countOfOptions = options.length;
    let currentOption = 0;

    return (
        <ButtonGroup>
            {
                _.map(options, option => {
                    currentOption++;
                    const key = option[ButtonOptionsFormFieldOptionProp.Key];
                    const optionValue = option[ButtonOptionsFormFieldOptionProp.Value];
                    const text = option[ButtonOptionsFormFieldOptionProp.Text];
                    const selectedColor = option[ButtonOptionsFormFieldOptionProp.SelectedColor];
                    const notSelectedColor = option[ButtonOptionsFormFieldOptionProp.NotSelectedColor];

                    let color = selectedValue === optionValue ? selectedColor : notSelectedColor;

                    return (
                        <React.Fragment key={key}>
                            <Button color={color} onClick={() => onValueChange({ value: optionValue })}>{text}</Button>
                            {currentOption !== countOfOptions ? <ButtonOr text='|' /> : null}
                        </React.Fragment>
                    );
                })
            }


        </ButtonGroup>
    );
}
