export const FieldType = Object.freeze({
    Dropdown: 'dropdown',
    DropdownMultiselect: 'dropdownMultiselect',
    DropdownSearchable: 'dropdownSearchable',
    Text: 'text',
    TextArea: 'textArea',
    Date: 'date',
    Month: 'month',
    Year: 'year',
    Password: 'password',
    DateTime: 'dateTime',
    Numeric: 'numeric',
    File: 'file',
    DownloadFile: 'downloadFile',
    TextWithInternalLink: 'textWithInternalLink',
    ModalValueSelector: 'modalValueSelector',
    BoolSlider: 'boolSlider',
    RichTextEditor: 'richTextEditor',
    HtmlViewer: 'htmlViewer',
    ImagePreviewField: 'imagePreview',
    ButtonOptionInput: 'buttonOptionInput',
});
