import React from 'react';
import _ from 'lodash';
import { TableFormCellProp } from '../../../../enum/apiResultProp/TableFormCellProp';
import { FormFieldProp } from '../../../../enum/apiResultProp/FormFieldProp';
import { TableFormCellType } from '../../../../enum/TableFormCellType';
import { Table, Button, Icon } from 'semantic-ui-react';
import AbstractButton from '../../../form/formButton/button/AbstractButton';
import AbstractField from '../../../form/formField/AbstractField';
import { ActionProp } from '../../../../enum/apiResultProp/ActionProp';
import { FieldType } from '../../../../enum/FieldType';

export default ({
    cellProps, rowKey, onInputFieldChange, updateInputFieldErrorAction, onActionDownload,
    onButtonClickMutateScreenWithPostData, onFieldChangeMutateScreenWithPostData,
    onFieldChangeMutateComponentWithPostData, openModalForm
}) => {
    const cellType = cellProps[TableFormCellProp.CellType];

    let cell = <Table.Cell textAlign='center'>This Cell Type is not registered - "{cellType}"</Table.Cell>;

    if (cellType === TableFormCellType.Buttons) {
        const buttons = cellProps[TableFormCellProp.Buttons];

        cell = (
            <Table.Cell textAlign='center' width={1}>
                <Button.Group>
                    {_.map(buttons, (buttonProps, key) => {
                        return (
                            <AbstractButton
                                key={key}
                                buttonProps={buttonProps}
                                onActionMutateScreenWithPostData={onButtonClickMutateScreenWithPostData}
                                onActionDownload={onActionDownload}
                                onActionPostDataAndRedirect={() => console.log('TODO')}
                                openModalForm={openModalForm}
                            />
                        );
                    })}
                </Button.Group>
            </Table.Cell>
        );
    } else if (cellType === TableFormCellType.InputField) {
        const fieldProps = cellProps[TableFormCellProp.FieldProps];
        const fieldName = fieldProps[FormFieldProp.Name];
        const fieldType = fieldProps[FormFieldProp.FieldType];

        let textAlign = 'left';
        if (fieldType === FieldType.BoolSlider || fieldType === FieldType.ButtonOptionInput) {
            textAlign = 'center';
        }

        cell = (
            <Table.Cell style={{ textAlign: textAlign }}>
                <AbstractField
                    fieldName={fieldName}
                    rowKey={rowKey}
                    fieldProps={fieldProps}
                    onChange={onInputFieldChange}
                    updateFieldErrorAction={updateInputFieldErrorAction}
                    onActionMutateScreenWithPostData={onFieldChangeMutateScreenWithPostData}
                    onFieldChangeMutateComponentWithPostData={onFieldChangeMutateComponentWithPostData}
                    inTableForm={true}
                />
            </Table.Cell>
        );
    } else if (cellType === TableFormCellType.Text) {
        const cellText = cellProps[TableFormCellProp.Text];

        cell = <Table.Cell textAlign='center'>{cellText}</Table.Cell>;
    } else if (cellType === TableFormCellType.BoolFlag) {
        const boolFlag = cellProps[TableFormCellProp.Flag];

        let icon = null;
        if (boolFlag) {
            icon = <Icon name='check' color='green' />
        } else {
            icon = <Icon name='close' color='red' />
        }

        cell = <Table.Cell textAlign='center'>{icon}</Table.Cell>;
    } else if (cellType === TableFormCellType.DownloadLink) {
        const linkText = cellProps[TableFormCellProp.Text];
        const downloadFileAction = cellProps[TableFormCellProp.DownloadFileAction];

        if (linkText && downloadFileAction) {
            const backEndUrl = downloadFileAction[ActionProp.BackEndUrl];
            const fileNameOnDownload = downloadFileAction[ActionProp.FileNameOnDownload];

            cell = (
                <Table.Cell textAlign='center'>
                    <span
                        onClick={() => onActionDownload({ backEndUrl, fileNameOnDownload })}
                        style={{ cursor: 'pointer', color: '#2185d0' }}
                    >
                        <Icon name='download' />
                        {linkText}
                    </span>
                </Table.Cell>
            );
        } else {
            cell = <Table.Cell textAlign='center'><Icon size='large' name='window close outline' color='black' /></Table.Cell>;
        }
    }

    return cell;
}
