export const ScreenComponentType = Object.freeze({
    Flow: 'flow',
    Form: 'form',
    ModalForm: 'modalForm',
    Buttons: 'buttons',
    Title: 'title',
    Table: 'table',
    TableForm: 'tableForm',
    Tree: 'tree',
    TreeWithSidePanel: 'treeWithSidePanel',
    InfoPanel: 'infoPanel',
    DataGrid: 'dataGrid',
    StatisticGroup: 'statisticGroup',
});
