import React from 'react';
import _ from 'lodash';
import { Popup } from 'semantic-ui-react';
import { ButtonType } from '../../../../enum/ButtonType';
import StandardButton from './StandardButton';
import { ActionType } from '../../../../enum/ActionType';
import { FormButtonProp } from '../../../../enum/apiResultProp/FormButtonProp';
import { ActionProp } from '../../../../enum/apiResultProp/ActionProp';
import history from '../../../../history';
import { ROUTES } from '../../../../helpers/routes';
import ConfirmationButton from './ConfirmationButton';
import DataGridRowButton from './DataGridRowButton';
import DataGridRowButtonWithConfirmation from './DataGridRowButtonWithConfirmation';
import DataGridButton from './DataGridButton';

export default ({
    buttonProps,
    onActionSubmitForm,
    onActionMutateScreenWithPostData,
    onActionMutateScreenNoPostData,
    reloadScreenWithPostDataOnButtonClick,
    onActionDownload,
    onActionLogout,
    onActionPostDataAndRedirect,
    openModalForm,
    onButtonClickMutateComponentWithPostData,
    onButtonClickMutateComponentNoPostData,
    getRequestAndRedirectExternalLink,
    className
}) => {
    const buttonType = buttonProps[FormButtonProp.ButtonType];
    const action = buttonProps[FormButtonProp.Action];
    const buttonName = buttonProps[FormButtonProp.ButtonName];
    const disableOnActionRequest = buttonProps[FormButtonProp.DisableOnActionRequest];
    const enableOnActionResponse = buttonProps[FormButtonProp.EnableOnActionResponse];
    const disabledMessage = buttonProps[FormButtonProp.DisabledMessage];
    const disabled = buttonProps[FormButtonProp.Disabled];
    const fluid = buttonProps[FormButtonProp.Fluid];

    let onButtonClick = () => { };
    if (action) {
        const actionType = action[ActionProp.ActionType];

        onButtonClick = () => {
            if (actionType === ActionType.PostDataAndRedirectFrontEnd) {
                const backEndUrl = action[ActionProp.BackEndUrl];
                const makeBackEndCallOnlyIfDataIsValid = action[ActionProp.MakeBackEndCallOnlyIfDataIsValid];
                const onCliclActionParams = { buttonName, backEndUrl, disableOnActionRequest, enableOnActionResponse, makeBackEndCallOnlyIfDataIsValid, makeBackEndCallOnlyIfDataIsValid };

                onActionPostDataAndRedirect(onCliclActionParams);
            } else if (actionType === ActionType.MutateScreenWithPostDataAction) {
                const backEndUrl = action[ActionProp.BackEndUrl];
                const onCliclActionParams = { buttonName, backEndUrl, disableOnActionRequest, enableOnActionResponse };

                onActionMutateScreenWithPostData(onCliclActionParams);
            } else if (actionType === ActionType.ReloadScreenWithPostDataAction) {
                const backEndUrl = action[ActionProp.BackEndUrl];
                const collectDataFromComponentName = action[ActionProp.CollectDataFromComponentName];
                const makeBackEndCallOnlyIfDataIsValid = action[ActionProp.MakeBackEndCallOnlyIfDataIsValid];
                const onCliclActionParams = { buttonName, backEndUrl, disableOnActionRequest, enableOnActionResponse, collectDataFromComponentName, makeBackEndCallOnlyIfDataIsValid };

                reloadScreenWithPostDataOnButtonClick(onCliclActionParams);
            } else if (actionType === ActionType.MutateScreenNoPostDataAction) {
                const backEndUrl = action[ActionProp.BackEndUrl];
                const onCliclActionParams = { buttonName, backEndUrl, disableOnActionRequest, enableOnActionResponse };

                onActionMutateScreenNoPostData(onCliclActionParams);
            } else if (actionType === ActionType.SubmitForm) {
                const backEndUrl = action[ActionProp.BackEndUrl];
                const onCliclActionParams = { buttonName, backEndUrl, disableOnActionRequest, enableOnActionResponse };

                onActionSubmitForm(onCliclActionParams);
            } else if (actionType === ActionType.DownloadFileAction) {
                const backEndUrl = action[ActionProp.BackEndUrl];
                const onCliclActionParams = { buttonName, backEndUrl, disableOnActionRequest, enableOnActionResponse };

                const fileNameOnDownload = action[ActionProp.FileNameOnDownload];
                onActionDownload({ ...onCliclActionParams, fileNameOnDownload });
            } else if (actionType === ActionType.LogoutAction) {
                onActionLogout();
            } else if (actionType === ActionType.RedirectFrontEnd) {
                let routeName = action[ActionProp.FrontEndRouteName];
                let routeParams = action[ActionProp.FrontEndRouteParams];

                let nextRoute = ROUTES.HOME_SCREEN_ROUTE.URL;
                if (ROUTES[routeName]) {
                    nextRoute = ROUTES[routeName].URL;

                    if (routeParams) {
                        _.forEach(routeParams, (paramValue, paramName) => {
                            nextRoute = nextRoute.replace(':' + paramName, paramValue);
                        });
                    }
                }

                history.push(nextRoute);
            } else if (actionType === ActionType.OpenModalFormAction) {
                let modalFormComponentName = action[ActionProp.ModalFormComponentName];
                openModalForm({ modalFormComponentName });
            } else if (actionType === ActionType.MutateComponentNoPostDataAction) {
                const backEndUrl = action[ActionProp.BackEndUrl];
                const onCliclActionParams = { buttonName, backEndUrl, disableOnActionRequest, enableOnActionResponse };

                onButtonClickMutateComponentNoPostData(onCliclActionParams);
            } else if (actionType === ActionType.MutateComponentWithPostDataAction) {
                const backEndUrl = action[ActionProp.BackEndUrl];
                const collectDataFromComponentName = action[ActionProp.CollectDataFromComponentName];
                const onCliclActionParams = { buttonName, backEndUrl, disableOnActionRequest, enableOnActionResponse, collectDataFromComponentName };

                onButtonClickMutateComponentWithPostData(onCliclActionParams);
            } else if (actionType === ActionType.ExternalRedirect) {
                const backEndUrl = action[ActionProp.BackEndUrl];
                const onCliclActionParams = { buttonName, backEndUrl, disableOnActionRequest, enableOnActionResponse };

                getRequestAndRedirectExternalLink(onCliclActionParams);
            } else if (actionType === ActionType.ExternalSiteLink) {
                const externalSiteLink = action[ActionProp.ExternalUrl];
                // window.location.href = externalSiteLink;
                window.open(externalSiteLink, "_blank", "noopener,noreferrer");
            }
        }
    }

    let button = null;

    if (buttonType === ButtonType.Standard) {
        button = (
            <StandardButton
                buttonProps={buttonProps}
                onButtonClick={onButtonClick}
                size='tiny'
            />
        );
    } else if (buttonType === ButtonType.WithConfirmation) {
        button = (
            <ConfirmationButton
                buttonProps={buttonProps}
                onButtonClick={onButtonClick}
                size='tiny'
            />
        );
    } else if (buttonType === ButtonType.DataGridRowButton) {
        button = (
            <DataGridRowButton
                buttonProps={buttonProps}
                onButtonClick={onButtonClick}
                size='tiny'
            />
        );
    } else if (buttonType === ButtonType.DataGridRowButtonWithConfirmation) {
        button = (
            <DataGridRowButtonWithConfirmation
                buttonProps={buttonProps}
                onButtonClick={onButtonClick}
                size='tiny'
            />
        );
    } else if (buttonType === ButtonType.DataGridButton) {
        button = (
            <DataGridButton
                buttonProps={buttonProps}
                onButtonClick={onButtonClick}
                size='tiny'
                className={className}
            />
        );
    }

    if (disabled && disabledMessage) {
        const buttonWithMessageOnHover = (
            <Popup
                content={disabledMessage}
                trigger={
                    <div style={{ display: "inline-block" }}>
                        {button}
                    </div>
                }
            />
        );

        button = buttonWithMessageOnHover;
    }

    return button;
}
