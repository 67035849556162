import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { FormButtonProp } from '../../../../enum/apiResultProp/FormButtonProp';

export default ({ buttonProps, onButtonClick, size }) => {
    const buttonName = buttonProps[FormButtonProp.ButtonName];
    const text = buttonProps[FormButtonProp.Text];
    const iconName = buttonProps[FormButtonProp.IconName];
    const disabled = buttonProps[FormButtonProp.Disabled];
    const hidden = buttonProps[FormButtonProp.Hidden];
    const showSpinner = buttonProps[FormButtonProp.ShowSpinner];
    const color = buttonProps[FormButtonProp.Color];
    const fluid = buttonProps[FormButtonProp.Fluid];
    const freeIconStyle = buttonProps[FormButtonProp.FreeIconStyle];

    if (hidden) {
        return null;
    }

    let iconElement = null;

    const buttonSettings = {
        key: buttonName,
        disabled: disabled,
        loading: showSpinner,
        color: color,
        onClick: onButtonClick,
        size: size,
        fluid: fluid,
        icon: false
    };
    if (iconName) {
        if (freeIconStyle) {
            iconElement = <Icon name={iconName} />

        } else {
            buttonSettings.icon = true;
            iconElement = <Icon name={iconName} />
    
            if (text) {
                buttonSettings.labelPosition = 'left';
            }
        }
    }

    return (
        <Button
            {...buttonSettings}
        >
            {iconElement}
            {text}
        </Button>
    );
}