export const InfoPanelProp = Object.freeze({
    Title: 'title',
    Text: 'text',
    ContentRows: 'contentRows',
    BulletMessages: 'bulletMessages',
    Closable: 'closable',
    Hidden: 'hidden',
    Style: 'style',
    Color: 'color',
    Size: 'size',
    IconName: 'iconName',
    Buttons: 'buttons',
});