import React from 'react';
import { connect } from 'react-redux';
import { login, updateFormInput, generateValidationCodeForPasswordForgot, loginSubmit } from '../actions/authActions';
import { Grid, Header, Icon, Form, Segment, Button, Message } from 'semantic-ui-react';
import history from '../history';
import { ROUTES } from '../helpers/routes';

class ForgotPassword extends React.Component {
    onInputChange = (formName, fieldName, fieldValue) => {
        this.props.updateFormInput(formName, fieldName, fieldValue);
    }

    onCodeGenerateClick = (formName, buttonName, username) => {
        this.props.generateValidationCodeForPasswordForgot(formName, buttonName, username);
    }

    onSubmitClick = () => {
        this.props.loginSubmit('forgotPassordForm', 'submitForm', 'change-password-forgot')
    }

    renderErrorMessage = (message) => {
        if (!message) {
            return null;
        }

        return (
            <Message error size='mini' style={{ marginTop: '0px' }}>
                {message}
            </Message>
        );
    }

    renderSuccessMessage = (message) => {
        if (!message) {
            return null;
        }

        return (
            <Message success size='mini' style={{ marginTop: '0px' }}>
                {message}
            </Message>
        );
    }

    renderForm = () => {
        return (
            <Grid centered columns={1} textAlign='center' style={{ marginTop: '0.5em' }}>
                <Grid.Row>
                    <Grid.Column centered style={{ width: '40%' }}>
                        <Segment raised>
                            <Header as='h4' style={{ color: '#3C8AD9' }} textAlign='center'>
                                <Icon name="question" /> Забравена Парола
                            </Header>
                            {this.renderErrorMessage(this.props.auth.forgotPassordForm.message.errorMessage)}
                            {this.renderSuccessMessage(this.props.auth.forgotPassordForm.message.successMessage)}
                            <Form size='tiny' warning>
                                <Form.Input
                                    action={{
                                        loading: this.props.auth.forgotPassordForm.buttonIsInAction.generateValidationCode,
                                        disabled: this.props.auth.forgotPassordForm.buttonIsInAction.generateValidationCode,
                                        size: 'tiny',
                                        content: 'Изпрати ми верификационен код',
                                        style: { backgroundColor: '#3C8AD9', color: 'white' },
                                        onClick: () => this.onCodeGenerateClick('forgotPassordForm', 'generateValidationCode', this.props.auth.forgotPassordForm.fields.username),
                                        icon: 'keyboard'
                                    }}
                                    icon='mail'
                                    iconPosition='left'
                                    label='Имейл / Потребителско Име'
                                    value={this.props.auth.forgotPassordForm.fields.username}
                                    onChange={(e) => this.onInputChange('forgotPassordForm', 'username', e.target.value)}
                                />
                            </Form>
                            <Form size='tiny'>
                                <Form.Input
                                    icon='keyboard'
                                    iconPosition='left'
                                    label='Въведи кода получен по имейл'
                                    value={this.props.auth.forgotPassordForm.fields.verificationCode}
                                    onChange={(e) => this.onInputChange('forgotPassordForm', 'verificationCode', e.target.value)}
                                />
                                <Form.Input
                                    type='password'
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    label='Нова Парола'
                                    value={this.props.auth.forgotPassordForm.fields.password}
                                    onChange={(e) => this.onInputChange('forgotPassordForm', 'password', e.target.value)}
                                />
                                <Form.Input
                                    type='password'
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    label='Повтори паролата'
                                    value={this.props.auth.forgotPassordForm.fields.confirmPassword}
                                    onChange={(e) => this.onInputChange('forgotPassordForm', 'confirmPassword', e.target.value)}
                                />
                                <Button
                                    loading={this.props.auth.forgotPassordForm.buttonIsInAction.submitForm}
                                    disabled={this.props.auth.forgotPassordForm.buttonIsInAction.submitForm}
                                    style={{ backgroundColor: '#3C8AD9', color: 'white' }}
                                    fluid
                                    size='tiny'
                                    onClick={this.onSubmitClick}
                                >
                                    Смени Паролата
                                </Button>
                            </Form>
                            <Message color='blue' size='mini'>
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push(ROUTES.LOGIN_ROUTE.URL);
                                    }}
                                    href='#'
                                >Искаш да се върнеш назад? Натисни Тук!</a>
                            </Message>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    render() {
        return (
            <>
                <Header
                    style={{ marginTop: '0.5em' }}
                    as='h3'
                    textAlign='center'
                    content='БЪЛГАРСКА ФЕДЕРАЦИЯ ПО МОТОЦИКЛЕТИЗЪМ'
                    subheader='Портал за управление на сертификати'
                />
                {this.renderForm()}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth };
}

export default connect(mapStateToProps, { login, updateFormInput, generateValidationCodeForPasswordForgot, loginSubmit })(ForgotPassword);
