//URL value should be unique
export const ROUTES = {
    // Old Roles
    ROLE_ROUTE: {
        URL: '/roles',
        NAME: 'ROLE_ROUTE',
        API_FETCH_URL: 'role/',
    },
    ROLE_READ_PAGE_ROUTE: {
        NAME: 'ROLE_READ_PAGE_ROUTE',
        URL: '/role/details/read/:id',
        URL_EDIT: '/role/details/edit/',
        URL_PARENT: '/roles',
        API_FETCH_URL: 'role/read/:id/form',
    },
    ROLE_CREATE_PAGE_ROUTE: {
        NAME: 'ROLE_CREATE_PAGE_ROUTE',
        URL: '/role/details/create',
        API_FETCH_URL: 'role/create/form',
        API_SUBMIT_URL: '/role/create/do',

        URL_BACK: '/roles',
        URL_SAVE_REDIRECT: '/role/details/read/:id'
    },
    ROLE_EDIT_PAGE_ROUTE: {
        NAME: 'ROLE_EDIT_PAGE_ROUTE',
        URL: '/role/details/edit/:id',
        API_FETCH_URL: 'role/edit/:id/form',
        API_SUBMIT_URL: '/role/edit/:id/do',

        URL_BACK: '/role/details/read/:id',
        URL_SAVE_REDIRECT: '/role/details/read/:id'
    },

    RACING_NUMBER_MANAGEMENT_GRID_ROUTE: {
        NAME: 'RACING_NUMBER_MANAGEMENT_GRID_ROUTE',
        URL: '/racing-number-management',
        API_FETCH_URL: 'racing-number-management/',
    },
    RACING_NUMBER_MANAGEMENT_CREATE_ROUTE: {
        NAME: 'RACING_NUMBER_MANAGEMENT_CREATE_ROUTE',
        URL: '/racing-number-management/create',
        API_FETCH_URL: 'racing-number-management/create/',
    },

    NAP_REPORT_SCREEN_ROUTE: {
        NAME: 'NAP_REPORT_SCREEN_ROUTE',
        URL: '/nap-report/screen',
        API_FETCH_URL: 'nap-report/screen/',
    },
    INSURANCE_MANAGEMENT_GRID_ROUTE: {
        NAME: 'INSURANCE_MANAGEMENT_GRID_ROUTE',
        URL: '/insurance-management',
        API_FETCH_URL: 'insurance-management/',
    },
    RACER_CARD_MANAGEMENT_GRID_ROUTE: {
        NAME: 'RACER_CARD_MANAGEMENT_GRID_ROUTE',
        URL: '/racer-card-management',
        API_FETCH_URL: 'racer-card-management/',
    },
    MY_RACERS_LICENSE_REPORT_GRID_ROUTE: {
        NAME: 'MY_RACERS_LICENSE_REPORT_GRID_ROUTE',
        URL: '/my-racers-license-report',
        API_FETCH_URL: 'my-racers-license-report/',
    },
    MY_RACERS_PAYMENT_MANAGEMENT_GRID_ROUTE: {
        NAME: 'MY_RACERS_PAYMENT_MANAGEMENT_GRID_ROUTE',
        URL: '/my-racers-payment-management',
        API_FETCH_URL: 'my-racers-payment-management/',
    },
    MY_RACERS_PAYMENT_MANAGEMENT_AFTER_PAYMENT_GRID_ROUTE: {
        NAME: 'MY_RACERS_PAYMENT_MANAGEMENT_AFTER_PAYMENT_GRID_ROUTE',
        URL: '/my-racers-payment-management-after-payment',
        API_FETCH_URL: 'my-racers-payment-management-after-payment/',
    },
    MY_RACERS_INSURANCE_REPORT_GRID_ROUTE: {
        NAME: 'MY_RACERS_INSURANCE_REPORT_GRID_ROUTE',
        URL: '/my-racers-insurance-report',
        API_FETCH_URL: 'my-racers-insurance-report/',
    },

    INVOICE_GRID_ROUTE: {
        NAME: 'INVOICE_GRID_ROUTE',
        URL: '/invoice',
        API_FETCH_URL: 'invoice/',
    },
    INVOICE_READ_ROUTE: {
        NAME: 'INVOICE_READ_ROUTE',
        URL: '/invoice/read/:id',
        API_FETCH_URL: 'invoice/read/:id/screen',
    },

    MY_TEAM_INVOICE_GRID_ROUTE: {
        NAME: 'MY_TEAM_INVOICE_GRID_ROUTE',
        URL: '/my-racing-team-invoice',
        API_FETCH_URL: 'my-racing-team-invoice/',
    },
    MY_TEAM_INVOICE_READ_ROUTE: {
        NAME: 'MY_TEAM_INVOICE_READ_ROUTE',
        URL: '/my-racing-team-invoice/read/:id',
        API_FETCH_URL: 'my-racing-team-invoice/read/:id/screen',
    },

    HOME_SCREEN_ROUTE: { 
        API_FETCH_URL: 'home-screen/',
        URL: '/', 
        NAME: 'HOME_SCREEN_ROUTE' 
    },
    LOGIN_ROUTE: { URL: '/login', NAME: 'LOGIN_ROUTE' },
    FORGOT_PASSOWRD_ROUTE: { URL: '/forgot-passowrd', NAME: 'FORGOT_PASSOWRD_ROUTE' },
    CHANGE_PASSOWRD_ROUTE: { URL: '/change-passowrd', NAME: 'CHANGE_PASSOWRD_ROUTE' },
    CHANGE_EMAIL_ROUTE: { URL: '/change-email', NAME: 'CHANGE_EMAIL_ROUTE' },

    SYS_ADMIN_PANEL_ROUTE: {
        API_FETCH_URL: 'get/sys/admin/panel/',
        URL: '/get/sys/admin/panel',
        NAME: 'SYS_ADMIN_PANEL_ROUTE'
    },
    PROCEDURES_ROUTE: {
        API_FETCH_URL: 'procedures/',
        URL: '/procedures',
        NAME: 'PROCEDURES_ROUTE'
    },

    TERMS_AND_CONDITIONS_DECISION_SCREEN: {
        NAME: 'TERMS_AND_CONDITIONS_READ_ROUTE',
        URL: '/terms-and-conditions/user-decision',
        API_FETCH_URL: 'terms-and-conditions/user-decision',
    },

    TERMS_AND_CONDITIONS_GRID_ROUTE: {
        NAME: 'TERMS_AND_CONDITIONS_GRID_ROUTE',
        URL: '/terms-and-conditions',
        API_FETCH_URL: 'terms-and-conditions/',
    },
    TERMS_AND_CONDITIONS_READ_ROUTE: {
        NAME: 'TERMS_AND_CONDITIONS_READ_ROUTE',
        URL: '/terms-and-conditions/read/:typeId',
        API_FETCH_URL: 'terms-and-conditions/read/:typeId/screen',
    },
    TERMS_AND_CONDITIONS_EDIT_ROUTE: {
        NAME: 'TERMS_AND_CONDITIONS_EDIT_ROUTE',
        URL: '/terms-and-conditions/edit/:typeId',
        API_FETCH_URL: 'terms-and-conditions/edit/:typeId/screen',
    },

    EXTERNAL_USER_GRID_ROUTE: {
        URL: '/external-user',
        NAME: 'EXTERNAL_USER_GRID_ROUTE',
        API_FETCH_URL: 'external-user/',
    },
    EXTERNAL_USER_READ_ROUTE: {
        NAME: 'EXTERNAL_USER_READ_ROUTE',
        URL: '/external-user/read/:id',
        API_FETCH_URL: 'external-user/read/:id/screen',
    },

    MY_RACING_EVENT_GRID_ROUTE: {
        URL: '/racer-license-review',
        NAME: 'MY_RACING_EVENT_GRID_ROUTE',
        API_FETCH_URL: 'racer-license-review/',
    },
    RACER_LICENSE_REVIEW_SCREEN_ROUTE: {
        NAME: 'RACER_LICENSE_REVIEW_SCREEN_ROUTE',
        URL: '/racer-license-review/:id/screen',
        API_FETCH_URL: 'racer-license-review/:id/screen',
    },

    RACER_SEASON_GRID_ROUTE: {
        NAME: 'RACER_SEASON_GRID_ROUTE',
        URL: '/racer-season',
        API_FETCH_URL: 'racer-season/',
    },
    RACER_SEASON_READ_ROUTE: {
        NAME: 'RACING_EVENT_READ_ROUTE',
        URL: '/racer-season/read/:seasonId',
        API_FETCH_URL: 'racer-season/read/:seasonId/screen',
    },
    RACER_SEASON_EDIT_ROUTE: {
        NAME: 'RACER_SEASON_EDIT_ROUTE',
        URL: '/racer-season/edit/:seasonId',
        API_FETCH_URL: 'racer-season/edit/:seasonId/screen',
    },

    RACING_EVENT_GRID_ROUTE: {
        NAME: 'RACING_EVENT_GRID_ROUTE',
        URL: '/racing-event',
        API_FETCH_URL: 'racing-event/',
    },
    RACING_EVENT_READ_ROUTE: {
        NAME: 'RACING_EVENT_READ_ROUTE',
        URL: '/racing-event/read/:id',
        API_FETCH_URL: 'racing-event/read/:id/screen',
    },
    RACING_EVENT_CREATE_ROUTE: {
        NAME: 'RACING_EVENT_CREATE_ROUTE',
        URL: '/racing-event/create',
        API_FETCH_URL: 'racing-event/create/screen',
    },
    RACING_EVENT_EDIT_ROUTE: {
        NAME: 'RACING_EVENT_EDIT_ROUTE',
        URL: '/racing-event/edit/:id',
        API_FETCH_URL: 'racing-event/edit/:id/screen',
    },

    RACER_LICENSE_REVIEW_GRID_ROUTE: {
        URL: '/racer-license-review',
        NAME: 'RACER_LICENSE_REVIEW_GRID_ROUTE',
        API_FETCH_URL: 'racer-license-review/',
    },

    RACER_LICENSE_REVIEW_SCREEN_ROUTE: {
        NAME: 'RACER_LICENSE_REVIEW_SCREEN_ROUTE',
        URL: '/racer-license-review/:seasonId/:racerId/screen',
        API_FETCH_URL: 'racer-license-review/:seasonId/:racerId/screen',
    },

    RACER_LICENSE_GRID_ROUTE: {
        NAME: 'RACER_LICENSE_GRID_ROUTE',
        URL: '/racer-license',
        API_FETCH_URL: 'racer-license/',
    },
    RACER_LICENSE_READ_ROUTE: {
        NAME: 'RACER_LICENSE_READ_ROUTE',
        URL: '/racer-license/read/:seasonId',
        API_FETCH_URL: 'racer-license/read/:seasonId/screen',
    },
    RACER_LICENSE_READ_AFTER_PAYMENT_ROUTE: {
        NAME: 'RACER_LICENSE_READ_AFTER_PAYMENT_ROUTE',
        URL: '/racer-license/read-after-payment/:seasonId',
        API_FETCH_URL: 'racer-license/read-after-payment/:seasonId/screen',
    },
    RACER_LICENSE_CREATE_ROUTE: {
        NAME: 'RACER_LICENSE_CREATE_ROUTE',
        URL: '/racer-license/create',
        API_FETCH_URL: 'racer-license/create/screen',
    },
    RACER_LICENSE_EDIT_ROUTE: {
        NAME: 'RACER_LICENSE_EDIT_ROUTE',
        URL: '/racer-license/edit/:seasonId',
        API_FETCH_URL: 'racer-license/edit/:seasonId/screen',
    },

    INSURANCE_GROUP_GRID_ROUTE: {
        NAME: 'INSURANCE_GROUP_GRID_ROUTE',
        URL: '/insurance-group',
        API_FETCH_URL: 'insurance-group/',
    },
    INSURANCE_GROUP_READ_ROUTE: {
        NAME: 'INSURANCE_GROUP_READ_ROUTE',
        URL: '/insurance-group/read/:id',
        API_FETCH_URL: 'insurance-group/read/:id/screen',
    },
    INSURANCE_GROUP_CREATE_ROUTE: {
        NAME: 'INSURANCE_GROUP_CREATE_ROUTE',
        URL: '/insurance-group/create',
        API_FETCH_URL: 'insurance-group/create/screen',
    },
    INSURANCE_GROUP_EDIT_ROUTE: {
        NAME: 'INSURANCE_GROUP_EDIT_ROUTE',
        URL: '/insurance-group/edit/:id',
        API_FETCH_URL: 'insurance-group/edit/:id/screen',
    },

    AGE_GROUP_GRID_ROUTE: {
        NAME: 'AGE_GROUP_GRID_ROUTE',
        URL: '/age-group',
        API_FETCH_URL: 'age-group/',
    },
    AGE_GROUP_READ_ROUTE: {
        NAME: 'AGE_GROUP_READ_ROUTE',
        URL: '/age-group/read/:id',
        API_FETCH_URL: 'age-group/read/:id/screen',
    },
    AGE_GROUP_CREATE_ROUTE: {
        NAME: 'AGE_GROUP_CREATE_ROUTE',
        URL: '/age-group/create',
        API_FETCH_URL: 'age-group/create/screen',
    },
    AGE_GROUP_EDIT_ROUTE: {
        NAME: 'AGE_GROUP_EDIT_ROUTE',
        URL: '/age-group/edit/:id',
        API_FETCH_URL: 'age-group/edit/:id/screen',
    },

    RACING_TEAM_GRID_ROUTE: {
        NAME: 'RACING_TEAM_GRID_ROUTE',
        URL: '/racing-team',
        API_FETCH_URL: 'racing-team/',
    },
    RACING_TEAM_READ_ROUTE: {
        NAME: 'RACING_TEAM_READ_ROUTE',
        URL: '/racing-team/read/:id',
        API_FETCH_URL: 'racing-team/read/:id/screen',
    },
    RACING_TEAM_CREATE_ROUTE: {
        NAME: 'RACING_TEAM_CREATE_ROUTE',
        URL: '/racing-team/create',
        API_FETCH_URL: 'racing-team/create/screen',
    },
    RACING_TEAM_EDIT_ROUTE: {
        NAME: 'RACING_TEAM_EDIT_ROUTE',
        URL: '/racing-team/edit/:id',
        API_FETCH_URL: 'racing-team/edit/:id/screen',
    },

    MY_RACING_TEAM_GRID_ROUTE: {
        NAME: 'MY_RACING_TEAM_GRID_ROUTE',
        URL: '/my-racing-team',
        API_FETCH_URL: 'my-racing-team/',
    },
    MY_RACING_TEAM_CREATE_ROUTE: {
        NAME: 'MY_RACING_TEAM_CREATE_ROUTE',
        URL: '/my-racing-team/create',
        API_FETCH_URL: 'my-racing-team/create/screen',
    },
    MY_RACING_TEAM_READ_ROUTE: {
        NAME: 'MY_RACING_TEAM_READ_ROUTE',
        URL: '/my-racing-team/read/:id',
        API_FETCH_URL: 'my-racing-team/read/:id/screen',
    },
    MY_RACING_TEAM_READ_AFTER_PAYMENT_ROUTE: {
        NAME: 'MY_RACING_TEAM_READ_AFTER_PAYMENT_ROUTE',
        URL: '/my-racing-team/read-after-payment/:id',
        API_FETCH_URL: 'my-racing-team/read-after-payment/:id/screen',
    },
    MY_RACING_TEAM_EDIT_ROUTE: {
        NAME: 'MY_RACING_TEAM_EDIT_ROUTE',
        URL: '/my-racing-team/edit/:id',
        API_FETCH_URL: 'my-racing-team/edit/:id/screen',
    },

    DISCIPLINE_GRID_ROUTE: {
        NAME: 'DISCIPLINE_GRID_ROUTE',
        URL: '/discipline',
        API_FETCH_URL: 'discipline/',
    },
    DISCIPLINE_READ_ROUTE: {
        NAME: 'DISCIPLINE_READ_ROUTE',
        URL: '/discipline/read/:id',
        API_FETCH_URL: 'discipline/read/:id/screen',
    },
    DISCIPLINE_CREATE_ROUTE: {
        NAME: 'DISCIPLINE_CREATE_ROUTE',
        URL: '/discipline/create',
        API_FETCH_URL: 'discipline/create/screen',
    },
    DISCIPLINE_EDIT_ROUTE: {
        NAME: 'DISCIPLINE_EDIT_ROUTE',
        URL: '/discipline/edit/:id',
        API_FETCH_URL: 'discipline/edit/:id/screen',
    },

    SEASON_GRID_ROUTE: {
        NAME: 'SEASON_GRID_ROUTE',
        URL: '/season',
        API_FETCH_URL: 'season/',
    },
    SEASON_READ_ROUTE: {
        NAME: 'SEASON_READ_ROUTE',
        URL: '/season/read/:id',
        API_FETCH_URL: 'season/read/:id/screen',
    },
    SEASON_CREATE_ROUTE: {
        NAME: 'SEASON_CREATE_ROUTE',
        URL: '/season/create',
        API_FETCH_URL: 'season/create/screen',
    },
    SEASON_EDIT_ROUTE: {
        NAME: 'SEASON_EDIT_ROUTE',
        URL: '/season/edit/:id',
        API_FETCH_URL: 'season/edit/:id/screen',
    },

    INTERNAL_USER_GRID_ROUTE: {
        NAME: 'INTERNAL_USER_GRID_ROUTE',
        URL: '/internal-user',
        API_FETCH_URL: 'internal-user/',
    },
    INTERNAL_USER_READ_ROUTE: {
        NAME: 'INTERNAL_USER_READ_ROUTE',
        URL: '/internal-user/read/:id',
        API_FETCH_URL: 'internal-user/read/:id/screen',
    },
    INTERNAL_USER_CREATE_ROUTE: {
        NAME: 'INTERNAL_USER_CREATE_ROUTE',
        URL: '/internal-user/create',
        API_FETCH_URL: 'internal-user/create/screen',
    },
    INTERNAL_USER_EDIT_ROUTE: {
        NAME: 'INTERNAL_USER_EDIT_ROUTE',
        URL: '/internal-user/edit/:id',
        API_FETCH_URL: 'internal-user/edit/:id/screen',
    },

    RACER_INFO_READ_ROUTE: {
        NAME: 'RACER_INFO_READ_ROUTE',
        URL: '/racer-info/read/screen',
        API_FETCH_URL: 'racer-info/read/screen',
    },
    RACER_INFO_EDIT_ROUTE: {
        NAME: 'RACER_INFO_EDIT_ROUTE',
        URL: '/racer-info/edit/screen',
        API_FETCH_URL: 'racer-info/edit/screen',
    },

    VEHICLE_CATEGORY_GRID_ROUTE: {
        NAME: 'VEHICLE_CATEGORY_GRID_ROUTE',
        URL: '/vehicle-category',
        API_FETCH_URL: 'vehicle-category/',
    },
    VEHICLE_CATEGORY_READ_ROUTE: {
        NAME: 'VEHICLE_CATEGORY_READ_ROUTE',
        URL: '/vehicle-category/read/:id',
        API_FETCH_URL: 'vehicle-category/read/:id/screen',
    },
    VEHICLE_CATEGORY_CREATE_ROUTE: {
        NAME: 'VEHICLE_CATEGORY_CREATE_ROUTE',
        URL: '/vehicle-category/create',
        API_FETCH_URL: 'vehicle-category/create/screen',
    },
    VEHICLE_CATEGORY_EDIT_ROUTE: {
        NAME: 'VEHICLE_CATEGORY_EDIT_ROUTE',
        URL: '/vehicle-category/edit/:id',
        API_FETCH_URL: 'vehicle-category/edit/:id/screen',
    },

    VEHICLE_BRAND_GRID_ROUTE: {
        NAME: 'VEHICLE_BRAND_GRID_ROUTE',
        URL: '/vehicle-brand',
        API_FETCH_URL: 'vehicle-brand/',
    },
    VEHICLE_BRAND_READ_ROUTE: {
        NAME: 'VEHICLE_BRAND_READ_ROUTE',
        URL: '/vehicle-brand/read/:id',
        API_FETCH_URL: 'vehicle-brand/read/:id/screen',
    },
    VEHICLE_BRAND_CREATE_ROUTE: {
        NAME: 'VEHICLE_BRAND_CREATE_ROUTE',
        URL: '/vehicle-brand/create',
        API_FETCH_URL: 'vehicle-brand/create/screen',
    },
    VEHICLE_BRAND_EDIT_ROUTE: {
        NAME: 'VEHICLE_BRAND_EDIT_ROUTE',
        URL: '/vehicle-brand/edit/:id',
        API_FETCH_URL: 'vehicle-brand/edit/:id/screen',
    },
}
